import "./App.css";
import AndroidIcon from "../assets/icons/android.svg";
import AppleIcon from "../assets/icons/apple.svg";
import InstagramIcon from "../assets/icons/instagram.svg";
import MenuIconSvg from "../assets/icons/menu.svg";
import VisaIcon from "../assets/icons/visa.png";
import MasterCardIcon from "../assets/icons/mastercard.png";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init();

  return (
    <div className="app">
      <header className="app-header">
        <div className="logo-block">
          <div className="text" data-aos="fade-up" data-aos-duration="2000">
            <p>oregano</p>
            <p>delivery</p>
          </div>

          <div
            className="description"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            <p>est.</p>
            <p>2018</p>
          </div>
        </div>
      </header>

      <section
        className="app-content"
        data-aos="fade-right"
        data-aos-duration="2000"
      >
        <div className="content-block">
          <div className="content-text">
            <p>завантажуй</p>
            <p>безкоштовний</p>
            <p>застосунок</p>
          </div>
          <div className="content-links">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=com.ithinkers.oregano&hl=uk"
              className="content-btn"
            >
              <img
                src={AndroidIcon}
                alt="android-app-button"
                className="content-btn"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/ua/app/oregano-sushi-pizza/id1462265237?l=uk"
            >
              <img
                src={AppleIcon}
                alt="android-app-button"
                className="content-btn"
              />
            </a>
          </div>
        </div>
      </section>

      <footer
        className="app-footer"
        data-aos="fade-down"
        data-aos-duration="1500"
      >
        <div className="buttons">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/oregano.delivery__rivne/"
          >
            <img
              src={InstagramIcon}
              alt="android-app-button"
              className="footer-btn"
            />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://oreganorivne.postershop.me/"
          >
            <div className="icon-with-title">
              <p className="icon-title">Меню</p>
              <img
                src={MenuIconSvg}
                alt="android-app-button"
                className="footer-btn"
              />
            </div>
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://oregano.rivne.ua/template/public_offer_agreement.pdf"
            className="footer-btn"
          >
            <div className="oferta-button">договір оферти</div>
          </a>
        </div>

        <div className="brands">
          <div className="footer-brand-img">
            <img src={VisaIcon} alt="android-app-button" />
          </div>

          <div className="footer-brand-img">
            <img
              src={MasterCardIcon}
              alt="android-app-button"
            />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
